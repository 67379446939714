const initialState = {
  cartItems: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        ...state,
        cartItems: [...state.cartItems, ...action.payload],
      };

    case "REMOVE_FROM_CART":
      return {
        ...state,
        cartItems: action.payload,
      };

    case "RESET_CART":
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
