import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import uniqWith from "lodash.uniqwith";
import isEqual from "lodash.isequal";
import isEmpty from "lodash.isempty";
import { inrFormat } from "../common/helper";
import get from "lodash.get";

class PopoverCartItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subTotal: 0,
    };
  }

  componentDidMount() {
    const { cartItems } = this.props;
    if (!isEmpty(cartItems)) {
      this.calculateTotal();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.cartItems, this.props.cartItems)) {
      this.calculateTotal();
    }
  }

  calculateTotal = () => {
    const { cartItems } = this.props;
    this.setState({
      subTotal: cartItems.reduce(
        (prevValue, cartItems) =>
          prevValue +
          (cartItems.serving_type === "half"
            ? cartItems.half_cost
            : cartItems.full_cost),
        0
      ),
    });
  };

  render() {
    const { cartItems } = this.props;
    const sortedItems = uniqWith(cartItems, isEqual);

    return (
      <div>
        <div
          className={`pop-body ${cartItems.length === 0 ? "display-none" : ""}`}
        >
          {sortedItems.map((item, index) => {
            const qty = item.is_half
              ? cartItems.filter(
                  (cItem) =>
                    cItem.fo_id === item.fo_id &&
                    item.serving_type === cItem.serving_type
                ).length
              : cartItems.filter((cItem) => cItem.fo_id === item.fo_id).length;
            return (
              <Table borderless responsive>
                <tbody>
                  <tr>
                    <td>
                      {item.fo_name} x {qty}{" "}
                      {get(item, "serving_type", false)
                        ? `(${item.serving_type})`
                        : ""}
                    </td>
                    <td align="right">
                      {inrFormat(
                        (item.serving_type === "half"
                          ? item.half_cost
                          : item.full_cost) * qty
                      )}
                      <br />
                    </td>
                  </tr>
                </tbody>
              </Table>
            );
          })}
          <hr />
        </div>

        <div className="pop-footer">
          <div className="pop-footer-total">
            Sub Total : {inrFormat(this.state.subTotal)}
          </div>
          {this.state.subTotal !== 0 ? (
            <Link to="/cart" className="pop-foot-btn">
              <div
                className="pop-footer-btn-div pointer-events-auto"
                onClick={() => this.props.toggle()}
              >
                Checkout
              </div>
            </Link>
          ) : (
            <div
              className="pop-footer-btn-div pointer-events-none"
              onClick={() => this.props.toggle()}
            >
              Checkout
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};
export default connect(mapStateToProps)(PopoverCartItems);
