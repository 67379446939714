import React, { Component } from "react";
import ModalForFullAndHalf from "./ModalForFullAndHalf";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import RemoveModal from "./RemoveModal";
import { removeFromCart } from "../../actions/cart.action";
import { removeFromCartUtil } from "../../common/helper";
class AddButtonForHalfFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRemoveModal: false,
      itemForRemove: {},
    };
  }

  openModalToRemoveItems = (item) => {
    this.setState({
      openRemoveModal: true,
      itemForRemove: item,
    });
  };

  openModalRemoveItem = () => {
    this.setState((prevState) => ({
      openRemoveModal: !prevState.openRemoveModal,
    }));
  };

  onItemRemoveClick = async (itemToRemove) => {
    const { cartItems } = this.props;
    const tempCartItems = removeFromCartUtil(cartItems, itemToRemove);
    await this.props.dispatch(removeFromCart(tempCartItems));
  };

  render() {
    const {
      cartItems,
      dishItem,
      setCurrentClickedItem,
      currentItem,
      openItemModal,
      openModalForItem,
    } = this.props;

    const { itemForRemove, openRemoveModal } = this.state;

    const cartItemArray = cartItems.filter(
      (cItem) => cItem.fo_id === dishItem.fo_id
    );

    return (
      <div>
        {!isEmpty(cartItemArray) ? (
          <div className="item-add d-flex">
            <div className="cart-div py-1">
              <span
                onClick={() =>
                  cartItemArray.length > 1
                    ? this.openModalToRemoveItems(dishItem)
                    : this.onItemRemoveClick(dishItem)
                }
              >
                <div className="cart-icon-minus px-2">
                  <FontAwesomeIcon icon={faMinus} />
                </div>
              </span>
              <div className="cart-txt px-2">
                <div disabled={true}>{cartItemArray.length}</div>
              </div>
              <span onClick={() => setCurrentClickedItem(dishItem)}>
                <div className="cart-icon-plus px-2">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </span>
            </div>
          </div>
        ) : (
          <div className="item-add">
            <button
              type="button"
              className="py-1 px-4"
              onClick={() => setCurrentClickedItem(dishItem)}
            >
              ADD
            </button>
          </div>
        )}
        {currentItem === dishItem.fo_id && openItemModal && (
          <ModalForFullAndHalf
            openItemModal={openItemModal}
            itemData={dishItem}
            openModalForItem={openModalForItem}
          />
        )}
        {openRemoveModal && (
          <RemoveModal
            openModalRemoveItem={this.openModalRemoveItem}
            openRemoveModal={openRemoveModal}
            itemForRemove={itemForRemove}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default connect(mapStateToProps)(AddButtonForHalfFull);
