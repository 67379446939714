import * as network from "./network";

const createOrder = (body = {}) => {
  return network.post(`order`, body);
};

const updatePaymentStatus = (body = {}) => {
  return network.post(`order/transaction`, body);
};

const updatePayment = (params = {}) => {
  return network.get(`payment`, params);
};

export default {
  createOrder,
  updatePaymentStatus,
  updatePayment,
};
