const initialState = {
  mobile: "",
  fullName: "",
  email: "",
  address: "",
  landmark: "",
  pincode: "",
  saveAddress: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MOBILE":
      return {
        ...state,
        mobile: action.payload,
      };
    case "ADD_FULLNAME":
      return {
        ...state,
        fullName: action.payload,
      };
    case "ADD_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "ADD_EMAIL_ERROR":
      return {
        ...state,
        emailError: action.payload,
      }
    case "ADD_ADDRESS":
      return {
        ...state,
        address: action.payload,
      };
    case "ADD_LANDMARK":
      return {
        ...state,
        landmark: action.payload,
      };
    case "ADD_PINCODE":
      return {
        ...state,
        pincode: action.payload,
      };
    case "SAVE_MY_ADDRESS":
      return {
        ...state,
        saveAddress: action.payload,
      };
    default:
      return state;
  }
};
