import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="footerBlock">
          <div className="footerDivBlock">
            <p className="p_footer">About us</p>
            <div className="line_div"></div>
            <p className="p_footer_body">
              {" "}
              Cottage Grill is an Indian brand which is famous for its Kebabs
              and Biryanis in the town. The restaurant business is all about
              good food. We at Cottage Grill believe in quality and taste as a
              primary objective of our business with consideration of all
              cleanliness and hygiene factors as per current covid norms.
            </p>
          </div>
          <div className="footerDivBlock">
            <p className="p_footer">Connect us on</p>
            <div className="line_div"></div>
            <p className="p_footer_body">
              <a
                className="socialMediablock"
                href="https://api.whatsapp.com/send?phone=919967940950&text=I%20Want%20To%20Order"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  size="lg"
                  className="social-media-logo-size"
                />
              </a>
              <a
                className="socialMediablock"
                href="https://www.instagram.com/cottagegrill28/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="lg"
                  className="social-media-logo-size"
                />
              </a>
              <a
                className="socialMediablock"
                href="https://www.facebook.com/cottagegrilldadar/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg"
                  className="social-media-logo-size"
                />
              </a>
            </p>
          </div>
          <div className="footerDivBlock lg_bright">
            <p className="p_footer lg_bright">
              <a href="https://goo.gl/maps/5P89oHBEKTPme29u9" target="_blank">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </a>
              {"  "}
              Desai Niwas, Nr. Portugese Church, Dadar(West), Mumbai -28
            </p>
            <p className="p_footer_body lg_bright arial-font">
              <a href="tel:+9122 24380018">022 24380018</a>&nbsp; /&nbsp;
              <a href="tel:+919967940950">9967940950</a>&nbsp; /&nbsp;
              <a href="tel:+919969483374">9969483374</a>
            </p>
            {/* <p className="p_footer_body lg_bright">support@cottagegrill.in</p> */}
            <p className="p_footer_body lg_bright arial-font">
              11:30 AM - 4:00 PM | 7:00 PM - 11:00 PM
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
