//Sync Actions
export const add_to_cart = (payload) => ({
  type: "ADD_TO_CART",
  payload,
});

export const remove_from_cart = (payload) => ({
  type: "REMOVE_FROM_CART",
  payload,
});

export const reset_cart = () => ({
  type: "RESET_CART",
});

//Async Actions
export const addItemToCart = (data) => {
  return (dispatch) => {
    dispatch(add_to_cart(data));
  };
};

export const removeFromCart = (data) => {
  return (dispatch) => {
    dispatch(remove_from_cart(data));
  };
};

export const resetCart = () => {
  return (dispatch) => {
    dispatch(reset_cart());
  };
};
