import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import "./index.scss";
import Home from "./components/Home";
import Cart from "./components/Cart";
import OrderConfirm from "./components/OrderConfirm";
import Trigger from "./components/Trigger";
import OrderConfirmCod from "./components/OrderConfirmCod";
import Page404 from "./components/Page404";
import OrderFail from "./components/OrderFail";
import ReactGA from "react-ga";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ReactGA.initialize("UA-196069278-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="parent container-fluid">
        <Switch>
          <Route exact path="/" component={withRouter(Home)} />
          <Route exact path="/cart" component={withRouter(Cart)} />
          <Route
            exact
            path="/order-success"
            component={withRouter(OrderConfirm)}
          />
          <Route
            exact
            path="/order-success-cod"
            component={withRouter(OrderConfirmCod)}
          />
          <Route exact path="/trigger" component={Trigger} />
          <Route exact path="/order-fail/:id" component={OrderFail} />

          {/* <====== Always keep them at last =======> */}
          <Route exact path="/404" component={Page404} />
          <Redirect from="*" to="/404" />
          {/* <=======================================> */}
        </Switch>
      </div>
    );
  }
}

export default withRouter(Routes);
