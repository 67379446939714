import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import { inrFormat } from "../../common/helper";
import { connect } from "react-redux";
import { addItemToCart } from "../../actions/cart.action";
import cloneDeep from "lodash.clonedeep";
import { compose } from "redux";
import { withAlert } from "react-alert";

class ModalForFullAndHalf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedServing: "full",
    };
  }

  onClickItem = (value) => {
    this.setState({
      selectedServing: value,
    });
  };

  onClickAddItemToCart = async (item) => {
    const { selectedServing } = this.state;
    const { cartItems } = this.props;
    const combinedArray = cartItems.filter(
      (foodItem) => foodItem.fo_id === item.fo_id
    );

    if (combinedArray.length < item.quantity_limit) {
      let modifiedItem = {};
      modifiedItem = cloneDeep(item);
      modifiedItem.serving_type = cloneDeep(selectedServing);
      await this.props.addItemToCart([modifiedItem]);
      this.props.openModalForItem();
    } else {
      this.props.alert.error(
        `Maximum qty of ${item.fo_name} can be ${item.quantity_limit}`
      );
    }
  };

  render() {
    const { openItemModal, itemData, openModalForItem } = this.props;
    const { selectedServing } = this.state;
    return (
      <Modal isOpen={openItemModal} toggle={openModalForItem}>
        <ModalHeader toggle={openModalForItem}>{itemData.fo_name}</ModalHeader>
        <ModalBody>
          <div>
            <div>Quantity</div>
            <div>
              <FormGroup tag="fieldset">
                <FormGroup check>
                  <div className="d-flex justify-content-between">
                    <Label check>
                      <Input
                        type="radio"
                        name="half"
                        checked={selectedServing === "half"}
                        onChange={() => this.onClickItem("half")}
                      />
                      Half
                    </Label>
                    <div>{inrFormat(itemData.half_cost)}</div>
                  </div>
                </FormGroup>
                <FormGroup check>
                  <div className="d-flex justify-content-between">
                    <Label check>
                      <Input
                        type="radio"
                        name="full"
                        checked={selectedServing === "full"}
                        onChange={() => this.onClickItem("full")}
                      />
                      Full
                    </Label>
                    <div>{inrFormat(itemData.full_cost)}</div>
                  </div>
                </FormGroup>
              </FormGroup>
            </div>
            <Button
              color="success"
              block
              onClick={() => this.onClickAddItemToCart(itemData)}
            >
              Add to order -{" "}
              {inrFormat(
                selectedServing === "half"
                  ? itemData.half_cost
                  : itemData.full_cost
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (data) => {
      dispatch(addItemToCart(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(ModalForFullAndHalf);
