import findIndex from "lodash.findindex";
import cloneDeep from "lodash.clonedeep";

export const inrFormat = (number) => {
  return Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);
};

export const removeFromCartUtil = (cartItems, itemToRemove) => {
  let tempCartItems = cloneDeep(cartItems);
  const index = findIndex(tempCartItems, itemToRemove);
  if (index >= 0) {
    tempCartItems.splice(index, 1);
  }
  return tempCartItems;
};

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
