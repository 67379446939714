import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { removeFromCart, add_to_cart } from "../../actions/cart.action";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import { removeFromCartUtil } from "../../common/helper";
import { withAlert } from "react-alert";
import { compose } from "redux";

const SimpleAddButton = ({
  dishItem,
  itemArray,
  dispatch,
  cartItems,
  pageType,
  alert,
}) => {
  const [itemQty, setItemQty] = useState(0);

  const removeItemFromCart = async (itemToRemove) => {
    const tempCartItems = removeFromCartUtil(cartItems, itemToRemove);
    await dispatch(removeFromCart(tempCartItems));
  };

  const addDirectToCart = (itemToAdd) => {
    if (itemToAdd.is_half) {
      dispatchItemToCartOrShowWarning(itemArray.length, itemToAdd);
    } else {
      dispatchItemToCartOrShowWarning(itemQty, itemToAdd);
    }
  };

  const dispatchItemToCartOrShowWarning = async (totalQty, itemToAdd) => {
    if (totalQty < itemToAdd.quantity_limit) {
      await dispatch(add_to_cart([itemToAdd]));
    } else {
      alert.error(
        `Maximum qty of ${itemToAdd.fo_name} can be ${itemToAdd.quantity_limit}`
      );
    }
  };

  useEffect(() => {
    const qty = dishItem.is_half
      ? itemArray.filter((item) => item.serving_type === dishItem.serving_type)
          .length
      : itemArray.filter((item) => item.fo_id === dishItem.fo_id).length;
    setItemQty(qty);
  }, [itemArray]);

  return (
    <div>
      <div>
        {!isEmpty(itemArray) && (
          <div className="item-add d-flex">
            <div className="cart-div py-1">
              <div className="cart-icon-minus px-2">
                <span onClick={() => removeItemFromCart(dishItem)}>
                  <FontAwesomeIcon icon={faMinus} />
                </span>
              </div>
              <div disabled={true}>
                <div className="cart-txt px-2">{itemQty}</div>
              </div>
              <div className="cart-icon-plus px-2">
                <span onClick={() => addDirectToCart(dishItem)}>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </div>
            </div>
          </div>
        )}
        {!dishItem.is_half && isEmpty(itemArray) && (
          <div className="item-add">
            <button
              type="button"
              className="py-1 px-4"
              onClick={() => addDirectToCart(dishItem)}
            >
              ADD
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default compose(withAlert(), connect(mapStateToProps))(SimpleAddButton);
