import React, { useEffect } from "react";

const Trigger = (props) => {
  const { responseData } = props.location.state;

  useEffect(() => {
    document.getElementById("submit").submit();
  }, [
    responseData.paykun.encrypted_request,
    responseData.paykun.merchant_id,
    responseData.paykun.access_token,
  ]);

  return (
    <div id="start-payment" className="order-final-bill">
      <form id="submit" method="POST" action={responseData.paykun.action}>
        <input
          type="hidden"
          name="encrypted_request"
          value={responseData.paykun.encrypted_request}
        />
        <input
          type="hidden"
          name="merchant_id"
          value={responseData.paykun.merchant_id}
        />
        <input
          type="hidden"
          name="access_token"
          value={responseData.paykun.access_token}
        />
        <button type="submit">submit</button>
      </form>
    </div>
  );
};

export default Trigger;
