 
 import React, { Component } from "react";
import { Table, Button, CustomInput, FormGroup, Form,Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { inrFormat } from "../common/helper";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import isEmpty from "lodash.isempty";
import api from "../api/api";
import get from "lodash.get";
import { withRouter } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import { add_to_cart, resetCart } from "../actions/cart.action";
import { compose } from "redux";
import { withAlert } from "react-alert";
import { find } from "lodash";

const SGST_RATE = 0.025;
const CGST_RATE = 0.025;
const BIRYANI_LIST=[
  {'fo_id':'F0115'},
  {'fo_id':'F0116'},
  {'fo_id':'F0117'},
  {'fo_id':'F0118'},
  {'fo_id':'F0120'},
  {'fo_id':'F0121'},
  {'fo_id':'F0122'},
]
const OFFER_STARTER=
{
dish_type_id: 3,
fo_descr: "",
fo_id: "F0257",
fo_name: "Offer Starter",
full_cost: 0,
half_cost: 0,
img_path: null,
is_available: false,
is_discountable: false,
is_half: false,
is_taxable: false,
is_veg: false,
quantity_limit: 5,
}
class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subTotal: 0,
      discount: 0,
      discounted_total: 0,
      sgst_amt: 0,
      cgst_amt: 0,
      grand_total: 0,
      taxable_total: 0,
      CurrentCODFlag: "",
      discount_percentage: "",
      offers: [],
      radBtnDisabled: true,
      isCod: "",
      responseData: {},
      orderBtnDisabled: false,
   /*  promocodeName:"",
     promocodeSuccessFailureMsg:"Please click on Apply to check validity of Promocode",
     promocodeApplicable:false,
     promocodeApplyRemoveBtnText:"Apply",*/
    };
  }

  componentDidMount() {
    const { cartItems } = this.props;
    this.getOfferData();
    if (!isEmpty(cartItems)) {
      this.calculateSubTotal();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.cartItems, this.props.cartItems) ||
      !isEqual(prevState.offers, this.state.offers)
    ) {
      this.calculateSubTotal();
    }
    if ( !isEqual(prevState.promocodeApplicable, this.state.promocodeApplicable)){
      this.calculateSubTotal();
    }
  }

  getOfferData = () => {
    api.fetchOffer().then((response) => {
      if (response.code === 200) {
        this.setState({
          offers: response.data,
        });
      }
    });
  };

  onChangeTxtInputs = (value, stateName) => {
    this.setState({
      [stateName]: value,
    });
  };

 /* checkPromocode =(appliedPromocode) =>{
    const { cartItems } = this.props;
    
    let biryani_item_found;
    let number_of_biryani=0;
    if (this.state.promocodeApplyRemoveBtnText==="Apply")
    {
        if (!isEmpty(appliedPromocode)){
            if(appliedPromocode.toLowerCase() ==="biryanioffer"){
                cartItems.map((food_items,index)=>{
                  biryani_item_found=find(BIRYANI_LIST,{'fo_id':food_items.fo_id});
                  if(!isEmpty(biryani_item_found)){
                    number_of_biryani=number_of_biryani+1;
                  }
                })
                if (number_of_biryani>=2){
                  this.setState({
                    promocodeSuccessFailureMsg:"Hurray!Promocode applied,Once order is placed,Restaurant team will reaach you for your choice of free starter.",
                    promocodeApplicable:true,
                    promocodeApplyRemoveBtnText:"Remove",
                  })
                  this.props.addToCart([OFFER_STARTER]);
                }else{
                  this.setState({
                    promocodeSuccessFailureMsg:"Not Applicable, minimum 2 Biryani required",
                    promocodeApplicable:false,
                    promocodeApplyRemoveBtnText:"Apply",
                  })
                }
            }
            else{
              this.setState({
                promocodeSuccessFailureMsg:"Invalid Promocode applied.",
                promocodeApplicable:false,
                promocodeApplyRemoveBtnText:"Apply",
              })
            }
        }
        else{
          this.setState({
            promocodeSuccessFailureMsg:"Promocode is blank",
            promocodeApplicable:false,
            promocodeApplyRemoveBtnText:"Apply",
          })
        }
    }
    else if(this.state.promocodeApplyRemoveBtnText="Remove") {
      this.setState({
        promocodeSuccessFailureMsg:"Please click on Apply to check validity of Promocode",
        promocodeApplicable:false,
        promocodeApplyRemoveBtnText:"Apply",
        promocodeName:"",
      })
    }
  }*/
  calculateSubTotal = () => {
    let fun_subTotal = 0;
    let fun_discount = 0;
    let fun_discounted_total = 0;
    let fun_sgst_amt = 0;
    let fun_cgst_amt = 0;
    let fun_grand_total = 0;
    let fun_taxable_total = 0;
    let applicable_total = 0;
    let fun_disc_per = 0;

    const { cartItems } = this.props;
    const { offers } = this.state;

    /*START To calculate sub total */
    fun_subTotal = cartItems.reduce(
      (prevValue, cartItems) =>
        prevValue +
        (cartItems.serving_type === "half"
          ? cartItems.half_cost
          : cartItems.full_cost),
      0
    );
    /*END To calculate sub total */

    /*START To calculate only total of items which are applicable for discount */
    applicable_total = cartItems.reduce(
      (prevValue, cartItems) =>
        prevValue +
        (cartItems.is_discountable === true
          ? cartItems.serving_type === "half"
            ? cartItems.half_cost
            : cartItems.full_cost
          : 0),
      0
    );
    /*END calculate only total of items which are applicable for discount */
    /*
    let offers = offer.OfferData;
    offers = offers.sort((a, b) => {
      return parseFloat(a.min_amount) - parseFloat(b.min_amount);
    });*/
    // let offers = this.state.offerData;

    /*START To check if any offer applicable or not and if yes then fetch discount percentage */
    console.log("cal---------------",this.state.promocodeApplicable);
    if (!isEmpty(offers) && !this.state.promocodeApplicable) {
      const max = offers[0]["min_amount"];

      offers.map((offerRow, index) => {
        /*    const from_dt = new Date(offerRow.from_dt);
        const to_dt = new Date(offerRow.to_dt);
*/
        if (applicable_total >= offerRow.min_amount) {
          if (max <= offerRow.min_amount) {
            fun_disc_per = offerRow.discount_per / 100;
          }
        }
      });
    }
    /*END To check if any offer applicable or not and if yes then fetch discount percentage */

    /*START if any discount is applicable then do further calcluations in this block*/
    if (fun_disc_per !== 0) {
      /*START discount is not applicable for all items, so calculate discounted total considering discounted amount for applicable items and regular amount for non discountable items*/
      fun_discounted_total = cartItems.reduce(
        (prevValue, cartItems) =>
          prevValue +
          (cartItems.is_discountable === true
            ? (cartItems.serving_type === "half"
                ? cartItems.half_cost
                : cartItems.full_cost) -
              (cartItems.serving_type === "half"
                ? cartItems.half_cost
                : cartItems.full_cost) *
                fun_disc_per
            : cartItems.serving_type === "half"
            ? cartItems.half_cost
            : cartItems.full_cost),
        0
      );
     /*END discount is not applicable for all items, so calculate discounted total considering discounted amount for applicable items and regular amount for non discountable items*/

      fun_discount = fun_subTotal - fun_discounted_total;/*Get total discount applied by subtracting above total (as discount is not applicable for each item)*/

      /*START with new discounted total calculate taxable amount as tax is not applicable for all items*/
      fun_taxable_total = cartItems.reduce(
        (prevValue, cartItems) =>
          prevValue +
          (cartItems.is_taxable === true
            ? (cartItems.is_discountable == true
                ? cartItems.serving_type === "half"
                  ? cartItems.half_cost
                  : cartItems.full_cost
                : 0) -
              (cartItems.is_discountable === true
                ? cartItems.serving_type === "half"
                  ? cartItems.half_cost
                  : cartItems.full_cost
                : 0) *
                fun_disc_per
            : 0),
        0
      );
      /*START with new discounted total calculate taxable amount as tax is not applicable for all items*/
    } /*END if any discount is applicable then do further calcluations in this block*/
    /*START if no offer applied then below else block*/
    else {
      fun_discount = 0;/*No offer no discount*/
      fun_discounted_total = fun_subTotal;/*No offer so subtotal is same as discounted total*/
      /*START count taxable total with current subtotal/discounted total as tax is not applicable for all items*/
      fun_taxable_total = cartItems.reduce(
        (prevValue, cartItems) =>
          prevValue +
          (cartItems.is_taxable === true
            ? cartItems.serving_type === "half"
              ? cartItems.half_cost
              : cartItems.full_cost
            : 0),
        0
      );
      /*START count taxable total with current subtotal/discounted total as tax is not applicable for all items*/
    }
    /*END if no offer applied then below else block*/

    fun_discount = fun_subTotal - fun_discounted_total;
    fun_sgst_amt = fun_taxable_total * SGST_RATE;
    fun_cgst_amt = fun_taxable_total * CGST_RATE;
    fun_grand_total = fun_discounted_total + fun_sgst_amt + fun_cgst_amt;
    this.setState({
      subTotal: fun_subTotal,
      taxable_total: fun_taxable_total,
      sgst_amt: fun_sgst_amt,
      cgst_amt: fun_cgst_amt,
      discount: fun_discount,
      discounted_total: fun_discounted_total,
      grand_total: fun_grand_total,
      discount_percentage: fun_disc_per,
    });
    if (fun_grand_total === 0) {
      this.setState({
        radBtnDisabled: true,
        CurrentCODFlag: "",
      });
    } else if (fun_grand_total > 0) {
      this.setState({
        radBtnDisabled: false,
      });
    }
  };

  onClickRadio = (CODStr) => {
    if (!isEmpty(CODStr)) {
      this.setState({
        isCod: CODStr,
      });
    } else {
      this.setState({
        isCod: "",
      });
    }
  };

  onClickValidations = () => {
    let StaticRestaurantSwitch = false;
    const { mobile } = this.props;
    const { fullName } = this.props;
    const { email } = this.props;
    const { address } = this.props;
    const { landmark } = this.props;
    const { pincode } = this.props;

    api.getMasterSwitchStatus().then((response) => {
      if (response.code === 200) {
        StaticRestaurantSwitch = cloneDeep(response.data.master_switch);

        if (!StaticRestaurantSwitch) {
          this.props.alert.error(
            "Ooops! Restaurant just went offline & order can not be placed.. You may contact on below contact details"
          );
        } else if (this.state.grand_total === 0) {
          this.props.alert.error("Please add items in the cart!");
        } else if (
          mobile === "" ||
          fullName === "" ||
          email === "" ||
          address === "" ||
          landmark === "" ||
          pincode === ""
        ) {
          this.props.alert.error(
            "Address Details like mobile, name, email, address, landmark or pincode can not be blank!"
          );
        } else if (mobile.toString().length !== 10) {
          this.props.alert.error("Please enter valid mobile number!");
        } else if (this.props.emailError) {
          this.props.alert.error("Please enter valid Email id!");
        } else if (pincode.toString().length !== 6) {
          this.props.alert.error("Please enter valid Pincode!");
        } else if (this.state.subTotal < 200) {
          this.props.alert.error("Minimum Order value should be 200 or more!");
        } else if (fullName.length > 100) {
          this.props.alert.error(
            "Please check fullname, can't be more than 100 characters."
          );
        } else if (address.length > 100) {
          this.props.alert.error(
            "Please check address, can't be more than 100 characters."
          );
        } else if (landmark.length > 40) {
          this.props.alert.error(
            "Please check landmark, can't be more than 40 characters."
          );
        } else if (email.length > 255) {
          this.props.alert.error(
            "Please check email, can't be more than 255 characters."
          );
        } else {
          this.placeOrder();
        }
      }
    });
  };

  placeOrder = async () => {
    const {
      cartItems,
      mobile,
      fullName,
      address,
      landmark,
      pincode,
      saveAddress,
      email,
      history,
    } = this.props;

    this.setState({
      orderBtnDisabled: true,
    });

    const { isCod } = this.state;
    const dishItems = cartItems.map((item) => ({
      id: item.fo_id,
      is_half: item.is_half,
      serving_type: get(item, "serving_type", null),
    }));

    const data = {
      food_items: dishItems,
      is_cod: isCod === "cod",
      mobile: mobile,
      fullName: fullName,
      address: address,
      landmark: landmark,
      pincode: pincode,
      email: email,
      save_cust_details: saveAddress === "" ? false : true,
    };

    if (!isEmpty(data)) {
      api.createOrder(data).then(async (response) => {
        if (response.data.code === 200) {
          const responseData = response.data.data;
          this.setState({
            responseData,
          });
          const data = {};
          data.customerEmail = responseData.customer.cust_email;
          data.customerMobile = responseData.customer.cust_mobile;
          data.customerName = responseData.customer.cust_name;
          data.amount = responseData.grand_total;

          if (isCod === "cod") {
            await this.props.resetCart();
            history.push({
              pathname: `/order-success-cod`,
              state: {
                orderData: response.data.data,
                isCod: isCod,
              },
            });
          } else {
            history.push({
              pathname: `/trigger`,
              state: {
                responseData: responseData,
              },
            });
          }
        }
      });
    }
  };

  render() {
    const { orderBtnDisabled } = this.state;
    return (
      <div>
        <div className="invoice-container pt-3">
          <div className="invoice-title">Order Summary</div>
          <div className="invoice-distrib">
            <Table borderless responsive>
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td align="right">{inrFormat(this.state.subTotal)}</td>
                </tr>
                <tr>
                  <td>
                    Discount <br />{" "}
                    {`(${this.state.discount_percentage * 100}%)`}
                  </td>
                  <td align="right">{inrFormat(this.state.discount)}</td>
                </tr>
               {/* <tr>
                  <td colSpan="2">
                    <FormGroup>
                                <InputGroup>
                                  <Label>Promocode:</Label>
                                  <Input type="text" id="promocode" value={this.state.promocodeName} onChange={(e) =>
                                  this.onChangeTxtInputs(e.target.value, "promocodeName")
                                  }/>
                                  <InputGroupAddon addonType="append">
                                    <Button color="primary" id="promocodeApplyRemoveBtn" onClick={()=>this.checkPromocode(this.state.promocodeName)}>{this.state.promocodeApplyRemoveBtnText}</Button>
                                  </InputGroupAddon>
                                </InputGroup>
                    </FormGroup>
                  </td>
                                </tr>
                <tr>
                  <td colSpan="2">
                    {this.state.promocodeSuccessFailureMsg}
                  </td>
                </tr>*/}
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Discounted Sub Total</td>
                  <td align="right">
                    {inrFormat(this.state.discounted_total)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>SGST</td>
                  <td align="right">{inrFormat(this.state.sgst_amt)}</td>
                </tr>
                <tr>
                  <td>CGST</td>
                  <td align="right">{inrFormat(this.state.cgst_amt)}</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr className="grand-total">
                  <td align="right">Grand total</td>
                  <td align="right">{inrFormat(this.state.grand_total)}</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="invoice-btns-container">
            <Form>
              <FormGroup radio>
                <div className="justify-content-around d-flex font-weight-bold">
                  <CustomInput
                    type="radio"
                    label="Cash on Delivery"
                    name="codRad"
                    id="codRadCOD"
                    onClick={() => this.onClickRadio("cod")}
                    disabled={this.state.radBtnDisabled}
                  ></CustomInput>
                 {/*  <CustomInput
                    type="radio"
                    label="Online Payment"
                    name="codRad"
                    id="codRadOP"
                    onClick={() => this.onClickRadio("online")}
                    disabled={this.state.radBtnDisabled}
              ></CustomInput> */ }
                </div>
              </FormGroup>
            </Form>
            <Button
              size="lg"
              className={`payment-btn ${
                isEmpty(this.state.isCod) ? "display-none" : "display-block"
              }`}
              onClick={() => this.onClickValidations()}
              disabled={orderBtnDisabled}
            >
              {`${orderBtnDisabled ? "Please wait..." : "Place Order"}`}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCart: () => {
      dispatch(resetCart());
    },
    addToCart: (foodItem) =>{
      dispatch(add_to_cart(foodItem));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
    mobile: state.addressReducer.mobile,
    fullName: state.addressReducer.fullName,
    address: state.addressReducer.address,
    landmark: state.addressReducer.landmark,
    pincode: state.addressReducer.pincode,
    saveAddress: state.addressReducer.saveAddress,
    email: state.addressReducer.email,
    emailError: state.addressReducer.emailError,
  };
};
export default compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(OrderSummary));
