import React, { Component } from "react";
import moment from "moment";
import { Table } from "reactstrap";
import get from "lodash.get";
import { inrFormat } from "../common/helper";
import isEmpty from "lodash.isempty";
import { withAlert } from "react-alert";
class FinalInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.alert.success(`YOUR ORDER PLACED SUCCESSFULLY!!`);
  }

  render() {
    const {
      order_id,
      customer,
      order_food_details,
      total_quantity,
      sub_total,
      total_discount,
      tax1,
      tax2,
      grand_total,
      is_cod,
      payment_id,
      cust_placed_at,
    } = this.props.orderData;

    const { cust_name, cust_mobile, address, landmark, pincode } = customer;
    return (
      <div>
        <div className="order-final-bill">
          <div className="bill-header"> cottagegrill.in</div>
          <div className="bill-title">Cottage Grill</div>
          <div className="bill-address">
            Desai Niwas, Nr. Portugese Church, Dadar(West)
          </div>
          <div>
            Mob : 9967940950
            <br />
            GST NO : 27AEJPD5737Q1ZW
          </div>
          <div className="bill-name">
            Name: {cust_name}
            <br />
            Mobile: {cust_mobile}
            <br />
            Address: {`${address}, ${landmark}, ${pincode}`}
            <br />
            Payment Type: {`${is_cod ? "Cash On Delivery" : "Online"}`}
            {!is_cod && <div>Payment Id: {payment_id}</div>}
          </div>
          <div className>
            Date : {moment.utc(cust_placed_at).format("DD-MM-YYYY, HH:mm:ss")}
            <br />
            Order No : {order_id}
          </div>
          <Table borderless>
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order_food_details.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{`${item.food_item.fo_name} ${
                      isEmpty(item.serving_type) ? "" : `(${item.serving_type})`
                    }`}</td>
                    <td>{item.quantity}</td>
                    <td>{item.per_unit_cost}</td>
                    <td>{item.per_unit_cost * item.quantity}</td>
                  </tr>
                );
              })}
              <tr>
                <td colspan="5">
                  <hr />
                </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  Total Qty : {total_quantity}{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  Sub Total{" "}
                </td>
                <td> {sub_total} </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  Discount{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  {get(order_food_details[0], "offer") === null
                    ? 0
                    : `${order_food_details[0].offer.discount_per}%`}
                </td>
                <td> {`(${total_discount})`} </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  CGST{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  2.5%{" "}
                </td>
                <td> {tax2} </td>
              </tr>
              <tr>
                <td colspan="2" className="text-right">
                  {" "}
                  SGST{" "}
                </td>
                <td colspan="2" className="text-right">
                  {" "}
                  2.5%{" "}
                </td>
                <td> {tax1} </td>
              </tr>
              <tr>
                <td colspan="5">
                  <hr />
                </td>
              </tr>
              <tr className="grand-total">
                <td colspan="4" className="text-center">
                  {" "}
                  Grand Total{" "}
                </td>
                <td> {inrFormat(grand_total)}</td>
              </tr>
              <tr>
                <td colspan="5">
                  <hr />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withAlert()(FinalInvoice);
