import React, { Component } from "react";
import FinalInvoice from "./FinalInvoice";
import Loader from "./Loader";
import isEmpty from "lodash.isempty";
import api from "../api/api";

class OrderConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: {},
    };
  }

  componentDidMount() {
    const transactionId = this.props.location.search.split("=")[1];

    api.updatePayment({ paymentId: transactionId }).then((response) => {
      this.setState({
        orderData: response.data,
      });
    });
  }
  render() {
    return (
      <div>
        <div className="order-main-div">
          {isEmpty(this.state.orderData) ? (
            <Loader size={60} />
          ) : (
            <FinalInvoice orderData={this.state.orderData} />
          )}
        </div>
      </div>
    );
  }
}

export default OrderConfirm;
