import React, { Component } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var Link = Scroll.Link;

class FloatingMenuBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  onClickOpenMenu = () => {
    this.setState((prevState) => ({
      openMenu: !prevState.openMenu,
    }));
  };
  render() {
    const { mobileNavMenu } = this.props;
    return (
      <div>
        <ButtonDropdown
          direction="up"
          isOpen={this.state.openMenu}
          toggle={this.onClickOpenMenu}
          className="flt-btn"
        >
          <DropdownToggle tag="button" type="button" className=" border-white">
            Menu
          </DropdownToggle>
          <DropdownMenu>
            {mobileNavMenu.map((fltMenu, index) => {
              return (
                <div>
                  {fltMenu.is_available && (
                    <DropdownItem active>
                      <Link
                        activeClass="active"
                        to={fltMenu.cuisine_name}
                        spy={true}
                        smoooth={true}
                        duration={500}
                        offset={-30}
                        containerId="containerElement"
                      >
                        {fltMenu.cuisine_name}
                      </Link>
                    </DropdownItem>
                  )}
                </div>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export default FloatingMenuBtn;
