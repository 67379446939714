import React, { Component } from "react";
import veg_img_path from "../media/parent/veg.png";
import non_veg_img_path from "../media/parent/non_veg.png";
import { inrFormat } from "../common/helper";
import { Collapse, Card, CardBody } from "reactstrap";
import Scroll from "react-scroll";
import { connect } from "react-redux";
import AddButtonForHalfFull from "./Cart/AddButtonForHalfFull";
import SimpleAddButton from "./Cart/SimpleAddButton";
import api from "../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

var Element = Scroll.Element;
class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDishType: "",
      openItemModal: false,
      currentItem: "",
      restaurantSwitch: false,
    };
  }

  componentDidMount() {
    api.getMasterSwitchStatus().then((response) => {
      if (response.code === 200) {
        this.setState({
          restaurantSwitch: response.data.master_switch,
        });
      }
    });
  }

  onClickOpenDishType = (dishtype) => {
    this.setState(() => ({
      currentDishType: dishtype,
    }));
  };

  setCurrentClickedItem = (item) => {
    this.setState({
      currentItem: item.fo_id,
      openItemModal: true,
    });
  };

  onItemAddClick = (item) => {};

  openModalForItem = () => {
    this.setState((prevState) => ({
      openItemModal: !prevState.openItemModal,
    }));
  };

  render() {
    const { mainMenu, cartItems } = this.props;
    const { openItemModal, currentItem } = this.state;
    return (
      <Element id="containerElement" className="cuisine" name="element">
        {mainMenu.map((menuItem, index) => {
          return (
            <div>
              {menuItem.is_available && (
                <div>
                  <Element
                    className="cusine-title"
                    name={menuItem.cuisine_name}
                  >
                    {menuItem.cuisine_name}
                  </Element>
                  {menuItem.dish_types.map((type, typeIndex) => {
                    return (
                      <div>
                        {type.is_available && (
                          <div className="item-cat">
                            <hr />
                            <div
                              className={`item-cat-title justify-content-between ${
                                type.dish_type_name.substring(0, 4) === "misc"
                                  ? "display-none"
                                  : "d-flex"
                              }`}
                              onClick={() =>
                                this.onClickOpenDishType(
                                  this.state.currentDishType ===
                                    type.dish_type_name
                                    ? ""
                                    : type.dish_type_name
                                )
                              }
                            >
                              <Element
                                name={`${type.dish_type_name}_${menuItem.cuisine_name}`}
                              >
                                {type.dish_type_name}
                              </Element>
                              <span className="d-lg-none">
                                <FontAwesomeIcon
                                  icon={
                                    this.state.currentDishType ===
                                    type.dish_type_name
                                      ? faAngleUp
                                      : faAngleDown
                                  }
                                  size="lg"
                                />
                              </span>
                            </div>

                            <Collapse
                              isOpen={
                                window.innerWidth < 500
                                  ? type.dish_type_name.substring(0, 4) ===
                                    "misc"
                                    ? type.dish_type_name
                                    : this.state.currentDishType ===
                                      type.dish_type_name
                                  : type.dish_type_name
                              }
                            >
                              <Card>
                                <CardBody>
                                  <div className="item-cat-box">
                                    {type.food_items.map((item, itemindex) => {
                                      return (
                                        <div>
                                          {item.is_available && (
                                            <div className="item-row">
                                              <div className="item-name">
                                                <img
                                                  src={
                                                    item.is_veg
                                                      ? veg_img_path
                                                      : non_veg_img_path
                                                  }
                                                  className="img-in-menu"
                                                  alt={
                                                    item.is_veg
                                                      ? "veg"
                                                      : "non-veg"
                                                  }
                                                />
                                                {item.fo_name}
                                              </div>
                                              <div className="item-details">
                                                <div className="d-flex justify-content-between pt-1">
                                                  {/* {item.fo_descr} */}
                                                  <div className="cost">
                                                    {inrFormat(item.full_cost)}
                                                  </div>
                                                  {this.state
                                                    .restaurantSwitch &&
                                                    (item.is_half ? (
                                                      <AddButtonForHalfFull
                                                        dishItem={item}
                                                        setCurrentClickedItem={
                                                          this
                                                            .setCurrentClickedItem
                                                        }
                                                        onItemAddClick={
                                                          this.onItemAddClick
                                                        }
                                                        currentItem={
                                                          currentItem
                                                        }
                                                        openItemModal={
                                                          openItemModal
                                                        }
                                                        openModalForItem={
                                                          this.openModalForItem
                                                        }
                                                        openModalToRemoveItems={
                                                          this
                                                            .openModalToRemoveItems
                                                        }
                                                        onItemRemoveClick={
                                                          this.onItemRemoveClick
                                                        }
                                                        disabled={
                                                          !this.state
                                                            .restaurantSwitch
                                                        }
                                                      />
                                                    ) : (
                                                      <SimpleAddButton
                                                        itemArray={cartItems.filter(
                                                          (cItem) =>
                                                            cItem.fo_id ===
                                                            item.fo_id
                                                        )}
                                                        dishItem={item}
                                                        disabled={
                                                          !this.state
                                                            .restaurantSwitch
                                                        }
                                                      />
                                                    ))}
                                                </div>
                                              </div>
                                              {item.is_half && this.state.restaurantSwitch && (
                                                <div className="d-flex justify-content-end customizable mr-1">
                                                  customizable
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </Element>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default connect(mapStateToProps)(Menu);
