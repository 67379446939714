//Sync Actions

export const add_mobile = (payload) => ({
  type: "ADD_MOBILE",
  payload,
});

export const add_fullName = (payload) => ({
  type: "ADD_FULLNAME",
  payload,
});

export const add_email = (payload) => ({
  type: "ADD_EMAIL",
  payload,
});

export const add_email_error = (payload) => ({
  type: "ADD_EMAIL_ERROR",
  payload,
});

export const add_address = (payload) => ({
  type: "ADD_ADDRESS",
  payload,
});

export const add_landmark = (payload) => ({
  type: "ADD_LANDMARK",
  payload,
});

export const add_pincode = (payload) => ({
  type: "ADD_PINCODE",
  payload,
});

export const save_address = (payload) => ({
  type: "SAVE_MY_ADDRESS",
  payload,
});

//Async Actions

export const addMobile = (data) => {
  return (dispatch) => {
    dispatch(add_mobile(data));
  };
};

export const addFullName = (data) => {
  return (dispatch) => {
    dispatch(add_fullName(data));
  };
};

export const addEmail = (data) => {
  return (dispatch) => {
    dispatch(add_email(data));
  };
};

export const addEmailError = (data) => {
  return (dispatch) => {
    dispatch(add_email_error(data))
  }
}

export const addAddr = (data) => {
  return (dispatch) => {
    dispatch(add_address(data));
  };
};

export const addLandmark = (data) => {
  return (dispatch) => {
    dispatch(add_landmark(data));
  };
};

export const addPincode = (data) => {
  return (dispatch) => {
    dispatch(add_pincode(data));
  };
};

export const saveMyAddress = (data) => {
  return (dispatch) => {
    dispatch(save_address(data));
  };
};
