import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import api from "../api/api";
import isEmpty from "lodash.isempty";
import { connect } from "react-redux";
import { addPincode } from "../actions/address.action";

class PinCodePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,
      pincode: "",
      pincodeError: "",
      proceedTxt: "",
      isDeliverable: false,
      isDataFetched: false,
    };
  }
  onChangePinCodeValue = (pinCodeValue) => {
    this.setState({
      pincode: pinCodeValue,
    });
  };
  onClickSubmitPinCode = () => {
    const { pincode } = this.state;
    if (isEmpty(pincode)) {
      this.setState({
        pincodeError: "Pincode is required !",
      });
    } else if (pincode.length > 6 || pincode.length < 6) {
      this.setState({
        pincodeError: "Pincode should be of 6 digits !",
      });
    } else {
      api.checkPincode({ pincode: pincode }).then((response) => {
        if (response.code === 200) {
          this.setState({
            isDataFetched: true,
          });
          if (!isEmpty(response.data)) {
            if (response.data.is_deliverable) {
              this.setState({
                pincodeError: `Whoaa! We are delivering here, please click proceed to order delicious food..`,
                proceedTxt: `Proceed`,
                isDeliverable: true,
              });
            }
          } else {
            this.setState({
              pincodeError:
                "Ooops! We are not delivering at this Pincode currently..",
              proceedTxt: `Still I want to proceed`,
            });
          }
        }
      });
    }
  };
  onClickCloseModal = () => {
    const { pincode } = this.state;

    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
    this.props.dispatch(addPincode(pincode));
  };
  render() {
    return (
      <div>
        <Modal isOpen={this.state.modalOpen}>
          <ModalHeader>Please enter your delivery Pin-code</ModalHeader>
          <ModalBody>
            <Input
              type="number"
              placeholder="Please enter Pin-code here"
              min="0"
              name="pincode"
              onChange={(value) =>
                this.onChangePinCodeValue(value.target.value)
              }
            ></Input>
            <div
              className={`${
                this.state.isDeliverable ? "text-success" : "pin-code-error"
              }`}
            >
              {this.state.pincodeError}
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.isDataFetched && !this.state.isDeliverable && (
              <Button
                color="primary"
                onClick={this.onClickSubmitPinCode}
                className="pincode-btn pr-4"
              >
                Check different pincode
              </Button>
            )}
            {!this.state.isDataFetched ? (
              <Button
                color="primary"
                onClick={this.onClickSubmitPinCode}
                className="pincode-btn"
              >
                Is it deliverable here?
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={this.onClickCloseModal}
                className="pincode-btn"
              >
                {this.state.proceedTxt}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect()(PinCodePopup);
