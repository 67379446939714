import React, { Component } from "react";
import FinalInvoice from "./FinalInvoice";
import Loader from "./Loader";
import get from "lodash.get";

class OrderConfirmCod extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="order-main-div">
          {get(this.props.location, "state.orderData", false) ? (
            <FinalInvoice orderData={this.props.location.state.orderData} />
          ) : (
            <Loader size={60} />
          )}
        </div>
      </div>
    );
  }
}

export default OrderConfirmCod;
