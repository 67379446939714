import React, { Component } from "react";
//import "./../index.scss";
import data from "../static_json/home_page.json";
import veg_img_path from "../media/parent/veg.png";
import non_veg_img_path from "../media/parent/non_veg.png";

import { inrFormat } from "../common/helper";
import AddButtonForHalfFull from "./Cart/AddButtonForHalfFull";
import SimpleAddButton from "./Cart/SimpleAddButton";
import { connect } from "react-redux";
import api from "../api/api";

class ImageMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: "",
      restaurantSwitch: false,
      imageMenuData: [],
      openItemModal: false,
    };
  }

  componentDidMount() {
    api.getMasterSwitchStatus().then((response) => {
      if (response.code === 200) {
        this.setState({
          restaurantSwitch: response.data.master_switch,
        });
      }
    });
    api.getImageMenuData().then((response) => {
      if (response.code === 200) {
        this.setState({
          imageMenuData: response.data,
        });
      }
    });
  }

  setCurrentClickedItem = (item) => {
    this.setState({
      currentItem: item.fo_id,
      openItemModal: true,
    });
  };

  openModalForItem = () => {
    this.setState((prevState) => ({
      openItemModal: !prevState.openItemModal,
    }));
  };

  render() {
    const { openItemModal, currentItem } = this.state;
    const { cartItems } = this.props;

    return (
      <div className="col-12 img-menu-container">
        <div className="row">
          {this.state.imageMenuData.map((item, index) => {
            return (
              <div key={index} className="col-6 pb-2">
                {/* <div className="bookmarkRibbon">
                <span className="bookmarkRibbonSpan">* Chef special</span>
              </div> */}
                <img src={item.img_path} alt="img" className="img-fluid" />
                <div className="footer-in-menu pt-2">
                  <div className="footer-in-menu-text">
                    <img
                      src={!item.is_veg ? non_veg_img_path : veg_img_path}
                      alt="veg_non_veg"
                      className="img-in-menu"
                    />
                    {item.fo_name}
                  </div>
                  <div className="d-flex justify-content-between pt-1 pb-3">
                    <div className="img-cost-div">
                      {inrFormat(item.full_cost)}
                    </div>
                    <div className="cart">
                      {this.state.restaurantSwitch && (
                        <div>
                          {item.is_available ? (
                            <div className="cart-btn-div" id="first">
                              {item.is_half ? (
                                <AddButtonForHalfFull
                                  dishItem={item}
                                  setCurrentClickedItem={
                                    this.setCurrentClickedItem
                                  }
                                  onItemAddClick={this.onItemAddClick}
                                  currentItem={currentItem}
                                  openItemModal={openItemModal}
                                  openModalForItem={this.openModalForItem}
                                  openModalToRemoveItems={
                                    this.openModalToRemoveItems
                                  }
                                  onItemRemoveClick={this.onItemRemoveClick}
                                  disabled={!this.state.restaurantSwitch}
                                />
                              ) : (
                                <SimpleAddButton
                                  itemArray={cartItems.filter(
                                    (cItem) => cItem.fo_id === item.fo_id
                                  )}
                                  dishItem={item}
                                  disabled={!this.state.restaurantSwitch}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="mt-1 mr-1 text-secondary">
                              Unavailable
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default connect(mapStateToProps)(ImageMenu);
