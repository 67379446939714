import { combineReducers } from "redux";
import cartReducer from "./cart.reducer";
import addressReducer from "./address.reducer";

const rootReducer = combineReducers({
  cartReducer,
  addressReducer,
});

export default rootReducer;
