import React, { Component } from "react";
//import "./../index.scss";
import ImageMenu from "./ImageMenu";
import SideMenu from "./SideMenu";
import Slider from "./Slider";
import Menu from "./Menu";
import FloatingMenuBtn from "./FloatingMenuBtn";
import api from "../api/api";
import PinCodePopup from "./PinCodePopup";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import Modal from "reactstrap/lib/Modal";
import { ModalHeader, Button } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MenuData: [],
      RestaurantSwitch: null,
      modalOpen: true,
    };
  }

  componentDidMount() {
    api.getMasterSwitchStatus().then((response) => {
      if (response.code === 200) {
        this.setState({
          RestaurantSwitch: response.data.master_switch,
          modalOpen: !response.data.master_switch,
        });
      }
    });
    api.fetchMenu().then((response) => {
      if (response.code === 200) {
        this.setState({
          MenuData: response.data,
        });
      }
    });
  }
  onClickCloseModal = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };
  render() {
    const { MenuData } = this.state;
    return (
      <div>
        {/*this.state.RestaurantSwitch && !isEmpty(this.props.pincode_state) && (
          <PinCodePopup />
        )*//*Mihir - Commented pincode popup as per Aditya's request. So that users could directly browse Menu 15-Sep-2021*/}
        {this.state.RestaurantSwitch === false && (
          <Modal isOpen={this.state.modalOpen}>
            <ModalHeader></ModalHeader>
            <ModalBody>
              Restuarant is offline but still you can browse the menu.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.onClickCloseModal()}>
                Okay
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <div className="initial-main-div">
          <div className="center-main-div">
            <Slider />
            <ImageMenu />
          </div>
        </div>
        <div className="second-main-div">
          <SideMenu navMenu={MenuData} />
          <Menu mainMenu={MenuData} />
        </div>
        <FloatingMenuBtn mobileNavMenu={MenuData} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pincode_state: state.addressReducer.pincode,
  };
};
export default connect(mapStateToProps)(Home);
