import React, { useState } from "react";
import { FormGroup, Input, Label, FormText } from "reactstrap";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import {
  addMobile,
  addFullName,
  addEmail,
  addAddr,
  addLandmark,
  addPincode,
  saveMyAddress,
  addEmailError,
} from "../actions/address.action";
import api from "../api/api";
import isEmpty from "lodash.isempty";
import { compose } from "redux";
import { withAlert } from "react-alert";

const onSubmit = (values) => {};

const Address = ({
  dispatch,
  mobile,
  fullName,
  address,
  landmark,
  pincode,
  email,
  saveAddress,
  alert,
}) => {
  const [errorPinCheck, setErrorPinCheck] = useState(false);

  const onBlurMobileWorking = async (mobile_value) => {
    if (mobile_value.length === 10) {
      await api
        .getSavedCustomerData({ cust_mobile: mobile_value })
        .then((response) => {
          if (response.code === 200) {
            if (!isEmpty(response.data)) {
              const fullName = response.data.cust_name;
              const email = response.data.cust_email;
              const address = response.data.address;
              const landmark = response.data.landmark;
              const pincode = response.data.pincode;
              const saveAddress = response.data.save_cust_details;
              dispatch(addFullName(fullName));
              dispatch(addEmail(email));
              dispatch(addAddr(address));
              dispatch(addLandmark(landmark));
              dispatch(addPincode(pincode));
              dispatch(saveMyAddress(saveAddress));
            } else {
              dispatch(addFullName(""));
              dispatch(addEmail(""));
              dispatch(addAddr(""));
              dispatch(addLandmark(""));
              dispatch(addPincode(""));
              dispatch(saveMyAddress(""));
            }
          }
        });
    }
  };
  const onBlurPincodeWorking = async (pincode_value) => {
    if (pincode_value.length === 6) {
      await api.checkPincode({ pincode: pincode_value }).then((response) => {
        if (response.code === 200) {
          if (!isEmpty(response.data)) {
            if (response.data.is_deliverable) {
              setErrorPinCheck(false);
            }
          } else {
            setErrorPinCheck(true);
            alert.error("Warning! We are not delivering at this pincode");
          }
        }
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={async (values) => {
        const errors = {};
        function validateEmail(email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }
        function onMouseMobileValidation(mobile) {
          var mobCheck = false;
          if (mobile.length > 10 || mobile.length < 10) {
            mobCheck = true;
          }
          return mobCheck;
        }

        function onMousePincodeLengthCheck(pincode) {
          var pinLenCheck = false;
          if (pincode.length > 6 || pincode.length < 6) {
            pinLenCheck = true;
          }
          return pinLenCheck;
        }

        if (!mobile) {
          errors.mobileNumber = "Required";
        } else if (onMouseMobileValidation(mobile)) {
          errors.mobileNumber = "Mobile Number must be of 10 digits";
        }
        if (!fullName) {
          errors.fullName = "Required";
        } else if (fullName.length > 100) {
          errors.fullName = "Name can't be greater than 100 characters.";
        }
        if (!email) {
          errors.email = "Required";
        } else if (!validateEmail(email)) {
          dispatch(addEmailError("Not a valid email adress"));
          errors.email = "Not a valid email adress";
        } else if (email.length > 255) {
          errors.email = "Email can't be greater than 255 characters.";
        } else {
          dispatch(addEmailError(""));
        }
        if (!address) {
          errors.address = "Required";
        } else if (address.length > 100) {
          errors.address = "Address can't be greater than 100 characters.";
        }
        if (!landmark) {
          errors.landmark = "Required";
        } else if (landmark.length > 40) {
          errors.landmark = "Landmark can't be greater than 40 characters.";
        }
        if (!pincode) {
          errors.pincode = "Required";
        } else if (onMousePincodeLengthCheck(pincode)) {
          errors.pincode = "Pincode should be of 6 digits !";
        } else if (errorPinCheck) {
          errors.pincode =
            "Warning! We are not delivering at this pincode.If you still  wish you may place an order and do pickup or meet within our radius.";
        }

        return errors;
      }}
      render={({ handleSubmit, values, submitting, validating, valid }) => (
        <div className="address-container pt-3">
          <div className="address-title">Address Details</div>
          <div className="address-inputs">
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Field name="mobileNumber">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        placeholder="Mobile Number"
                        type="number"
                        name="txtMno"
                        id="txtMno"
                        value={mobile}
                        max={10}
                        min={0}
                        invalid={meta.error && meta.touched}
                        onChange={async (e) => {
                          onBlurMobileWorking(e.target.value);
                          await dispatch(addMobile(e.target.value));
                        }}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup check name="saveAddress">
                <Input
                  type="checkbox"
                  name="cbSaveAdd"
                  id="cbSaveAdd"
                  checked={saveAddress}
                  onChange={async (e) =>
                    await dispatch(saveMyAddress(e.target.checked))
                  }
                />
                <Label for="cbSaveAdd" check>
                  Yes, save my address for my future orders.
                </Label>
              </FormGroup>
              <FormGroup>
                <Field name="fullName">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        placeholder="Full Name"
                        type="text"
                        name="txtName"
                        id="txtName"
                        value={fullName}
                        invalid={meta.error && meta.touched}
                        onChange={async (e) =>
                          await dispatch(addFullName(e.target.value))
                        }
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="email">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        placeholder="Email Address"
                        type="email"
                        name="txtEmail"
                        id="txtEmail"
                        value={email}
                        invalid={meta.error && meta.touched}
                        onChange={async (e) =>
                          await dispatch(addEmail(e.target.value))
                        }
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                      <FormText color="muted">
                        Your emailbox will only be bugged for order tracking, no
                        other spam.
                      </FormText>
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="address">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        placeholder="Address"
                        name="txtAdd"
                        id="txtAdd"
                        type="text"
                        invalid={meta.error && meta.touched}
                        onChange={async (e) =>
                          await dispatch(addAddr(e.target.value))
                        }
                        value={address}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="landmark">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        placeholder="Landmark"
                        name="txtLandmark"
                        id="txtLandmark"
                        type="text"
                        invalid={meta.error && meta.touched}
                        onChange={async (e) =>
                          await dispatch(addLandmark(e.target.value))
                        }
                        value={landmark}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Field name="pincode">
                  {({ input, meta }) => {
                    return (
                      <div>
                        <Input
                          {...input}
                          placeholder="Pincode"
                          name="txtPincode"
                          id="txtPincode"
                          type="text"
                          invalid={meta.error && meta.touched}
                          onChange={(e) => {
                            onBlurPincodeWorking(e.target.value);
                            dispatch(addPincode(e.target.value));
                          }}
                          value={pincode}
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    );
                  }}
                </Field>
              </FormGroup>
            </form>
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    mobile: state.addressReducer.mobile,
    fullName: state.addressReducer.fullName,
    address: state.addressReducer.address,
    landmark: state.addressReducer.landmark,
    pincode: state.addressReducer.pincode,
    saveAddress: state.addressReducer.saveAddress,
    email: state.addressReducer.email,
  };
};

export default compose(withAlert(), connect(mapStateToProps))(Address);
