import React, { Component } from "react";
import { Table } from "reactstrap";
import { inrFormat } from "../common/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import uniqWith from "lodash.uniqwith";
import isEqual from "lodash.isequal";
import get from "lodash.get";
import SimpleAddButton from "./Cart/SimpleAddButton";
import { Link } from "react-router-dom";

class CartItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueCartItems: [],
    };
  }

  componentDidMount() {
    const { cartItems } = this.props;
    if (!isEmpty(cartItems)) {
      this.setUniqItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.cartItems, this.props.cartItems)) {
      this.setUniqItems();
    }
  }

  setUniqItems = () => {
    const { cartItems } = this.props;
    this.setState({
      uniqueCartItems: uniqWith(cartItems, isEqual),
    });
  };

  render() {
    const { uniqueCartItems } = this.state;
    const { cartItems } = this.props;
    return (
      <div>
        <div className="cart-title">Your Cart items</div>
        <div className="cart-table">
          <Table borderless responsive>
            {!isEmpty(uniqueCartItems) && (
              <thead bordered="true">
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
            )}
            <tbody>
              {!isEmpty(uniqueCartItems) ? (
                uniqueCartItems.map((item, index) => {
                  const qty = item.is_half
                    ? cartItems.filter(
                        (cItem) =>
                          cItem.fo_id === item.fo_id &&
                          item.serving_type === cItem.serving_type
                      ).length
                    : cartItems.filter((cItem) => cItem.fo_id === item.fo_id)
                        .length;
                  return (
                    <tr key={`${item.fo_id}_${index}`}>
                      <td>{item.fo_name}{" "} {get(item, "serving_type", false)
                        ? `(${item.serving_type})`
                        : ""}</td>
                      <td>
                        <span className="cost">
                          {inrFormat(
                            get(item, "serving_type", false) === "half"
                              ? item.half_cost
                              : item.full_cost
                          )}
                        </span>
                      </td>
                      <td>
                        <SimpleAddButton
                          itemArray={
                            item.is_half
                              ? cartItems.filter(
                                  (cItem) =>
                                    cItem.fo_id === item.fo_id &&
                                    cItem.serving_type === item.serving_type
                                )
                              : cartItems.filter(
                                  (cItem) => cItem.fo_id === item.fo_id
                                )
                          }
                          dishItem={item}
                        />
                      </td>
                      <td>
                        <span className="cost">
                          {inrFormat(
                            (get(item, "serving_type", false) === "half"
                              ? item.half_cost
                              : item.full_cost) * qty
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="text-center">
                  <Link to="/">Browse Menu</Link>
                </div>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default connect(mapStateToProps)(CartItems);
