import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import Routes from "./routes";
import { Provider } from "react-redux";
import configureStore from "./store";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
const mobile = require("is-mobile");

const options = {
  // you can also just use 'bottom center'
  position: mobile() ? positions.MIDDLE : positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "60px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

ReactDOM.render(
  <Provider store={configureStore()}>
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
