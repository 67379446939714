import React from "react";

const OrderFail = () => {
  return (
    <div className="order-fail">
      <div>
        <h1>Order Failed</h1>
      </div>
      <div>Your order didn't place successfully.</div>
      <div>
        If your amount is debited then please call us at any of this number:{" "}
        <b> 022 24380018 / 9967940950 / 9969483374 </b>
      </div>
    </div>
  );
};

export default OrderFail;
