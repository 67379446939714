import React, { Component } from "react";
import CartItems from "./CartItems";
import Address from "./Address";
import OrderSummary from "./OrderSummary";
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="cart-main-div">
          <CartItems />
          <Address />
          <OrderSummary />
        </div>
      </div>
    );
  }
}

export default Cart;
