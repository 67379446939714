import React from "react";
import Svg404 from "./Svg404";
import "../scss/page404.scss";

const Page404 = () => {
  return (
    <div className="main">
      <Svg404 />
    </div>
  );
};

export default Page404;
