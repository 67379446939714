import React, { Component } from "react";
//import "./../index.scss";
import header_logo from "../media/parent/header_logo.png";
import PopoverCartItems from "./PopoverCartItems";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, Popover, PopoverHeader, PopoverBody, Badge } from "reactstrap";
import api from "../api/api";
import { Link, withRouter } from "react-router-dom";
const mobile = require("is-mobile");
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popOpen: false,
      RestaurantSwitch: false,
    };
  }

  componentDidMount() {
    api.getMasterSwitchStatus().then((response) => {
      if (response.code === 200) {
        this.setState({
          RestaurantSwitch: response.data.master_switch,
        });
      }
    });
  }

  onClickOpenCart = () => {
    this.setState((prevState) => ({
      popOpen: !prevState.popOpen,
    }));
  };

  render() {
    const { cartItems } = this.props;
    return (
      <div className="header-pnl d-flex align-items-center justify-content-between">
        <Link to="/" className="header-pnl-logo">
          <img
            src="https://cottagegrill.in/images/cottage_grill_logo.png"
            alt="Logo"
            height="100%"
            width="100%"
          />
        </Link>
        <div className="d-flex">
          <div className="mt-2 header-pnl-cart">
            <a
              className="text-white"
              href="https://cottagegrill.in/images/cottage_grill_menu.pdf"
              target="_blank"
            >
              <span>Menu </span>
              <FontAwesomeIcon icon={faDownload} size="lg" />
            </a>
          </div>
          {this.state.RestaurantSwitch && (
            <div className="header-pnl-cart">
              <Button
                className="header-pnl-cart-btn"
                id="btnShowCart"
                onClick={() =>
                  mobile() ? this.props.history.push(`/cart`) : null
                }
              >
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faShoppingBag} size="lg" />
                  <span class="fa-layers-text fa-inverse">
                    {cartItems.length}
                  </span>
                </span>
                <div className="header-pnl-cart-txt pl-1 d-none d-lg-inline">
                  Cart
                </div>
              </Button>
              {!mobile() && (
                <Popover
                  placement="bottom"
                  isOpen={this.state.popOpen}
                  target="btnShowCart"
                  toggle={this.onClickOpenCart}
                  trigger="legacy"
                  modifiers={{ flip: { behavior: ["bottom"] } }}
                >
                  <PopoverHeader>Your Cart items</PopoverHeader>
                  <PopoverBody>
                    <PopoverCartItems toggle={this.onClickOpenCart} />
                  </PopoverBody>
                </Popover>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};
export default connect(mapStateToProps)(withRouter(Header));
