import React, { Component } from "react";

import Scroll from "react-scroll";

var Link = Scroll.Link;

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCuisine: "Indian",
    };
  }

  onClickCuisine = (cuisineName) => {
    this.setState({
      currentCuisine: cuisineName,
    });
  };
  render() {
    const { navMenu } = this.props;
    return (
      <div className="desk-side-menu" id="desk-side-menu">
        <ul>
          {navMenu.map((SideMenuData, index) => {
            return (
              <div>
                {SideMenuData.is_available && (
                  <li key={`${SideMenuData.cuisine_name}_${index}`}>
                    <Link
                      activeClass="active"
                      to={SideMenuData.cuisine_name}
                      spy={true}
                      smoooth={true}
                      duration={500}
                      offset={-30}
                      containerId="containerElement"
                      className="side-menu-cuisine"
                      onClick={() =>
                        this.onClickCuisine(
                          this.state.currentCuisine ===
                            SideMenuData.cuisine_name
                            ? ""
                            : SideMenuData.cuisine_name
                        )
                      }
                    >
                      {SideMenuData.cuisine_name}
                    </Link>
                    <ul
                      className={`cuisine-submenu ${
                        this.state.currentCuisine === SideMenuData.cuisine_name
                          ? "display-block"
                          : "display-none"
                      }`}
                      id="submenu"
                    >
                      {SideMenuData.dish_types.map((dishType, index) => {
                        return (
                          <div>
                            {dishType.is_available && (
                              <li
                                key={`${dishType.dish_type_name}_${index}`}
                                className={
                                  dishType.dish_type_name.substring(0, 4) ===
                                  "misc"
                                    ? "display-none"
                                    : "display-block"
                                }
                              >
                                <Link
                                  activeClass="active"
                                  to={`${dishType.dish_type_name}_${SideMenuData.cuisine_name}`}
                                  spy={true}
                                  smoooth={true}
                                  duration={500}
                                  offset={-30}
                                  containerId="containerElement"
                                  className="cursor-for-link"
                                >
                                  {dishType.dish_type_name}
                                </Link>
                              </li>
                            )}
                          </div>
                        );
                      })}
                    </ul>
                  </li>
                )}
              </div>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default SideMenu;
