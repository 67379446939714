import pincode from "./pincode";
import * as network from "./network";
import menu from "./menu";
import order from "./order";
import offer from "./offer";
import customer from "./customer";
import masterswitch from "./masterswitch";
import imageMenu from "./imageMenu";
export default {
  ...network,
  ...pincode,
  ...menu,
  ...order,
  ...offer,
  ...customer,
  ...masterswitch,
  ...imageMenu,
};
