import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import veg_img_path from "../../media/parent/veg.png";
import non_veg_img_path from "../../media/parent/non_veg.png";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import { inrFormat } from "../../common/helper";
import SimpleAddButton from "./SimpleAddButton";
import uniqBy from "lodash.uniqby";
import isEqual from "lodash.isequal";
import orderBy from "lodash.orderby";

class RemoveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemArray: [],
    };
  }

  componentDidMount() {
    const { itemForRemove, cartItems } = this.props;
    this.setState({
      itemArray: orderBy(
        cartItems.filter((cItem) => cItem.fo_id === itemForRemove.fo_id),
        ["serving_type"],
        ["desc"]
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { itemForRemove, cartItems } = this.props;
    if (!isEqual(prevProps.cartItems, this.props.cartItems)) {
      const itemArray = orderBy(
        cartItems.filter((cItem) => cItem.fo_id === itemForRemove.fo_id),
        ["serving_type"],
        ["desc"]
      );
      if (isEmpty(itemArray)) {
        this.props.openModalRemoveItem();
      }
      this.setState({
        itemArray,
      });
    }
  }

  render() {
    const { openRemoveModal, openModalRemoveItem } = this.props;
    const { itemArray } = this.state;
    return (
      <Modal isOpen={openRemoveModal} toggle={openModalRemoveItem}>
        <ModalHeader toggle={openModalRemoveItem}>
          Remove your items
        </ModalHeader>
        <ModalBody>
          {!isEmpty(itemArray) &&
            uniqBy(itemArray, "serving_type").map((item, index) => {
              return (
                <div key={`${item.fo_name}-${index}`} className="item-row">
                  <div className="item-name">
                    <img
                      src={item.is_veg ? veg_img_path : non_veg_img_path}
                      className="img-in-menu"
                      alt={item.is_veg ? "veg" : "non-veg"}
                    />
                    {item.fo_name}
                  </div>
                  <div className="d-flex justify-content-between pt-1">
                    <div className="item-descr">
                      {item.fo_descr}
                      <span className="cost">
                        {item.serving_type === "half"
                          ? inrFormat(item.half_cost)
                          : inrFormat(item.full_cost)}
                      </span>
                    </div>
                    <SimpleAddButton itemArray={itemArray} dishItem={item} />
                  </div>
                </div>
              );
            })}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

export default connect(mapStateToProps)(RemoveModal);
